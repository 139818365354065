import type { Blueprint } from 'vuetify'
import { aunblend, lifecycleColors, themeColors } from '@/settings/colors.consts'
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg'

// const primaryColor = themeColors.primary;

// https://vuetifyjs.com/en/features/blueprints/

const borderColor = themeColors.grey[200]
export const blueprint: Blueprint = {
  display: {
    mobileBreakpoint: 'lg',
    thresholds: {
      xs: 0,
      sm: 600,
      md: 960,
      lg: 1280,
      xl: 1920,
      xxl: 2560,
    },
  },
  theme: {
    themes: {
      light: {
        colors: {
          // Set theme colors here
          'primary': lifecycleColors[500],
          'primary-darken-1': lifecycleColors[500],
          'secondary': themeColors.secondary[500],
          'error': themeColors.error[500],
          'success': themeColors.success[500],
          'warning': themeColors.warning[500],
          'info': themeColors.secondary[500],
          'surface': '#fff',
          'background': themeColors.grey[50],
        },
        variables: {
          'border-color': borderColor,
          'field-border-opacity-override': aunblend(0.38, borderColor, '#ffffff'),
          'disabled-opacity': 0.5,
        },
      },
    },
  },
  icons: {
    defaultSet: 'mdi',
    aliases: {
      ...aliases,
      monetize: 'M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10s10-4.48 10-10S17.52 2 12 2zm1.41 16.09v.58c0 .73-.6 1.33-1.33 1.33h-.01c-.73 0-1.33-.6-1.33-1.33v-.6c-1.33-.28-2.51-1.01-3.01-2.24c-.23-.55.2-1.16.8-1.16h.24c.37 0 .67.25.81.6c.29.75 1.05 1.27 2.51 1.27c1.96 0 2.4-.98 2.4-1.59c0-.83-.44-1.61-2.67-2.14c-2.48-.6-4.18-1.62-4.18-3.67c0-1.72 1.39-2.84 3.11-3.21v-.6c0-.73.6-1.33 1.33-1.33h.01c.73 0 1.33.6 1.33 1.33v.62c1.38.34 2.25 1.2 2.63 2.26c.2.55-.22 1.13-.81 1.13h-.26c-.37 0-.67-.26-.77-.62c-.23-.76-.86-1.25-2.12-1.25c-1.5 0-2.4.68-2.4 1.64c0 .84.65 1.39 2.67 1.91s4.18 1.39 4.18 3.91c-.02 1.83-1.39 2.83-3.13 3.16z',
      datasheet: 'M2.182 2A2.182 2.182 0 0 0 0 4.182v15.272a2.182 2.182 0 0 0 2.182 2.182h19.636A2.182 2.182 0 0 0 24 19.454V4.182A2.182 2.182 0 0 0 21.818 2H2.182Zm0 2.182h19.636v15.272H2.182V4.182Zm10.91 3.273a1.091 1.091 0 1 0-2.183 0v8.726a1.091 1.091 0 1 0 2.182 0V7.455Zm-4.365 2.18a1.09 1.09 0 0 0-2.18 0v6.546a1.09 1.09 0 1 0 2.18 0V9.636Zm8.728 2.729a1.091 1.091 0 0 0-2.182 0v3.817a1.091 1.091 0 1 0 2.182 0v-3.817Z',
      sideNavigation: 'M5 21q-.825 0-1.412-.587T3 19V5q0-.825.588-1.412T5 3h14q.825 0 1.413.588T21 5v14q0 .825-.587 1.413T19 21zm7-2h7V5h-7z'
    },
    sets: {
      mdi,
    },
  },
  defaults: {}
}
