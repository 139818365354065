/**
 * plugins/index.ts
 *
 * Automatically included in `./src/main.ts`
 */

import type { QueryOptions } from '@tanstack/query-core'
import type { VueQueryPluginOptions } from '@tanstack/vue-query'
import type { App } from 'vue'
import router from '@/router'
import { authStateManager } from '@/services/authStateManager'
import pinia from '@/stores'
import errorsConst from '@back/common/errors.const'
import { captureException } from '@sentry/vue'
import { QueryClient, VueQueryPlugin } from '@tanstack/vue-query'
import { useStorage } from '@vueuse/core'
import { setupI18n } from './i18n.plugin'
import { setupMotion } from './motion.plugin'
import { portalVue } from './portal.plugin'
import { setupPosthog } from './posthog.plugin'
import { setupSentry } from './sentry.plugin'
import vuetify from './vuetify.plugin'

// Custom Retry Logic
// type of QueryOptions['retry']
const customRetry: QueryOptions['retry'] = (failureCount, error) => {
  if ('status' in error && error.status === 404) {
    // Don't retry if the error is a 404. It's not found!
    return false
  }
  // If the server returns a 403 with the message "User Not Authorized", do not retry.
  const noRetryErrors = [
    errorsConst.userGuards.userNotAuthorized,
    errorsConst.userGuards.userNotAuthenticated,
    errorsConst.userGuards.userAccountDisabled,
    errorsConst.organizationGuards.organizationDisabled,
  ]
  // @ts-expect-error the eval of the const error message to string is intentional
  if (noRetryErrors.includes(error.message)) {
    return false
  }
  return failureCount < 3
}

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      retry: customRetry,
      throwOnError: (error) => {
        if ('status' in error) {
          if (error.status === 401 || error.status === 403) {
            // Update auth state storage to notify other tabs
            authStateManager.handleAuthError(error.status, error.message)

            // Capture error in Sentry
            captureException(error, {
              tags: {
                status: error.status,
                type: 'auth_error'
              }
            })
          }
        }
        return false
      },
      staleTime: 1000 * 60 * 5, // 5 minutes
      gcTime: 1000 * 60 * 30, // 30 minutes
    },
    mutations: {
      onError: (error) => {
        if ('status' in error && (error.status === 401 || error.status === 403)) {
          authStateManager.handleAuthError(error.status)
        }
      }
    }
  },
})
const vueQueryOptions: VueQueryPluginOptions = {
  enableDevtoolsV6Plugin: true,
  queryClient,
  // queryClientConfig: {
  //   defaultOptions: {
  //     queries: {
  //       gcTime: 1000 * 60 * 60 * 24,
  //       // staleTime: 1000 * 10,
  //     },
  //   },
  // },
}

export function registerPlugins(app: App) {
  app.use(vuetify)
  app.use(pinia) // pinia should be created early on, before router because router uses it
  app.use(router)
  app.use(portalVue)
  app.use(VueQueryPlugin, vueQueryOptions)
  setupI18n(app)
  setupPosthog(app)
  setupSentry(app)
  setupMotion(app)
}
