import type { RouteLocationNormalized } from 'vue-router'
import { defineStore } from 'pinia'

export const useLastRouteStore = defineStore('lastRoute', {
  state: () => ({
    lastRoute: null as RouteLocationNormalized | null,
  }),
  actions: {
    setLastRoute(route: RouteLocationNormalized) {
      this.lastRoute = route
    },
  },
})
