/**
 * plugins/vuetify.ts
 *
 * Framework documentation: https://vuetifyjs.com`
 */

// Composables
import { createVuetify } from 'vuetify'
import { VBtn } from 'vuetify/components/VBtn'
import { VCard } from 'vuetify/components/VCard'
import { VExpansionPanel } from 'vuetify/components/VExpansionPanel'
import { VTreeview } from 'vuetify/labs/VTreeview'

// Styles
import '@/styles/main.scss'
import '@/styles/components/components.scss'
import { itemsPerPageOptions } from '@back/common/paginationWithSort.const'
import { blueprint } from '@/settings'

// https://vuetifyjs.com/en/introduction/why-vuetify/#feature-guides
export default createVuetify({
  aliases: {
    VPageCard: VCard,
    VCircleIconBtn: VBtn,
    SymContentPanel: VExpansionPanel,
  },
  defaults: {
    VPageCard: {
      border: true,
      elevation: 5,
      class: 'v-page-card',
    },
    // TODO: Have adam create consistent sizes for this component
    VCircleIconBtn: {
      color: 'primary',
      variant: 'outlined',
      class: 'v-circle-icon-btn',
    },
    SymContentPanel: {
      class: 'sym-content-panel',
    },
    VTextField: {
      variant: 'outlined',
    },
    VSelect: {
      variant: 'outlined',
    },
    VTextarea: {
      variant: 'outlined',
    },
    VAutocomplete: {
      variant: 'outlined',
    },
    VCombobox: {
      variant: 'outlined',
    },
    VFileInput: {
      variant: 'outlined',
    },
    VBtn: {
      variant: 'flat',
    },
    VCardActions: {
      VBtn: {
        variant: 'flat',
      },
    },
    VDataTableFooter: {
      itemsPerPageOptions,
    },
    VDataTableServer: {
      itemsPerPage: itemsPerPageOptions[0].value,
    },
    VDataTable: {
      itemsPerPage: itemsPerPageOptions[0].value,
    },
    VTooltip: {
      openDelay: 500,
      eager: false
    },
    VTabs: {
      density: 'compact',
      sliderColor: 'primary',
      color: 'primary',
    },
  },
  components: {
    VTreeview,
  },
  blueprint,
})
