import type { ExtendedRouteConfig } from '@/router'
import { FGA_ROLES } from '@back/plugins/fga/permissionSecurity.enums'
import { RouterView } from 'vue-router'

export const financeRoutes: ExtendedRouteConfig = {
  path: 'finance',
  components: {
    drawer: () => import('@/modules/requestIQ/components/DrawerNavigation.vue'),
    nav: () => import('@/layouts/components/NavigationBar.vue'),
    default: RouterView,
  },
  meta: {
    layout: 'NavDrawerLayout',
    authorization: {
      value: [
        FGA_ROLES.sales.slug,
        FGA_ROLES.demo_request_approver.slug,
      ]
    },
    featureFlags: ['purchase_requests_demo']
  },
  children: [
    // TODO: Retain organization selection if set, otherwise default to all
    {
      path: '',
      name: 'ListFinanceSystemsDemo',
      component: () => import('./views/ListFinanceSystemsDemo2.vue'),
    },
    {
      path: ':modality/:priority/:requestType/requests',
      name: 'ListFinanceRequestsDemo',
      component: () => import('./views/ListFinanceRequestsDemo2.vue'),
      props: true
    },
    {
      path: ':modality/:priority/:requestType/:requestId',
      name: 'ViewFinanceRequestDemo',
      component: () => import('./views/ViewFinanceRequestDemo.vue'),
      props: true
    }
  ]
}
