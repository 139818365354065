import { posthogFeatureFlags } from '@common/consts/posthogFeatureFlagsConst'
import { t } from 'elysia'

export interface FGA_ROLE_TYPE {
  slug: string
  name: string
  description: string
  orgType: string | null // null means all org types
  features: FgaFeatureSlug[]
  featureFlag?: string
  inheritsIfAnyOf?: () => string[]
  inheritsIfAny?: true // All roles in the org type
  inheritsAllFeatures?: true
}

export interface FGA_ROLES_TYPE {
  [slug: string]: FGA_ROLE_TYPE
}
export const FGA_ORG_TYPES = {
  customer: 'customer',
  admin: 'admin',
}

export interface FgaFeatureWide {
  slug: string
  name: string
  description: string
  featureFlag?: string
}

interface FGA_FEATURES_TYPE {
  [slug: string]: FgaFeatureWide
}
export const FGA_FEATURES = {
  asset_iq: {
    slug: 'asset_iq',
    name: 'Asset IQ Lite',
    description: 'To Do',
  },
  asset_iq_capital_manager: {
    slug: 'asset_iq_capital_manager',
    name: 'Asset IQ Capital Manager',
    description: 'To Do',
  },
  asset_iq_recalls: {
    slug: 'asset_iq_recalls',
    name: 'Asset IQ Recalls',
    description: 'To Do',
  },
  asset_iq_infosec: {
    slug: 'asset_iq_infosec',
    name: 'Asset IQ Infosec',
    description: 'To Do',
  },
  asset_iq_pro: {
    slug: 'asset_iq_pro',
    name: 'Asset IQ Pro',
    description: 'To Do',
    featureFlag: posthogFeatureFlags.assetIqDashboardGroups,
  },
  asset_iq_pro_dashboard_access_management: {
    slug: 'asset_iq_pro_dashboard_access_management',
    name: 'Asset IQ Dashboard Access Management',
    description: '',
    featureFlag: posthogFeatureFlags.assetIqDashboardGroups,
  },
  request_iq: {
    slug: 'request_iq',
    name: 'Request IQ',
    description: 'To Do',
  },
  // ! TO BE REMOVED
  request_iq_demo: {
    slug: 'request_iq_demo',
    name: 'Request IQ Demo',
    description: 'To Do',
  },
} as const satisfies FGA_FEATURES_TYPE

export const FGA_ROLES = {
  user: {
    slug: 'user',
    name: 'User',
    description: 'Basic user role',
    orgType: null, // All org types
    features: [],
    inheritsIfAny: true,
  },
  developer: {
    slug: 'developer',
    name: 'Developer',
    description: 'Symplsoft Role for developers with extended permissions',
    orgType: FGA_ORG_TYPES.admin,
    features: [],
  },
  sales: {
    slug: 'sales',
    name: 'Sales',
    description: 'Symplsoft Role for sales team members',
    orgType: FGA_ORG_TYPES.admin,
    features: [],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.admin.slug,
    ],
  },
  admin: {
    slug: 'admin',
    name: 'Administrator',
    description: 'Symplsoft Role for Administrative Access',
    orgType: FGA_ORG_TYPES.admin,
    features: [],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.developer.slug,
    ],
    inheritsAllFeatures: true,
  },
  org_owner: {
    slug: 'org_owner',
    name: 'Organization Owner',
    description: 'Allows the user to manage the entire account for the organization. This includes billing, adding administrators, and managing users.',
    orgType: FGA_ORG_TYPES.customer,
    features: [],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.admin.slug,
    ],
  },
  org_admin: {
    slug: 'org_admin',
    name: 'Organization Administrator',
    description: 'Allows the user to add other users to the organization and manage their permissions.',
    orgType: FGA_ORG_TYPES.customer,
    features: [],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_owner.slug,
    ],
  },
  asset_data_publisher: {
    slug: 'asset_data_publisher',
    name: 'Asset Data Publisher',
    description: 'Allows User to Upload and Manage Stored Asset Data',
    orgType: FGA_ORG_TYPES.customer,
    features: [FGA_FEATURES.asset_iq.slug, FGA_FEATURES.asset_iq_pro.slug] as const,
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  asset_data_viewer: {
    slug: 'asset_data_viewer',
    name: 'Asset Data Viewer',
    description: 'Allows User to View Dashboards',
    orgType: FGA_ORG_TYPES.customer,
    features: [FGA_FEATURES.asset_iq.slug, FGA_FEATURES.asset_iq_pro.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  asset_data_admin: {
    slug: 'asset_data_admin',
    name: 'assetIQ Pro Admin',
    description: 'Allows User to Manage assetIQ Pro',
    orgType: FGA_ORG_TYPES.customer,
    features: [FGA_FEATURES.asset_iq_pro.slug],
    featureFlag: posthogFeatureFlags.assetIqDashboardGroups,
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  purchase_request_submitter: {
    slug: 'purchase_request_submitter',
    name: 'Purchase Request Submitter',
    description: 'Role for submitting purchase requests',
    orgType: FGA_ORG_TYPES.customer,
    features: [FGA_FEATURES.request_iq.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  purchase_request_approver: {
    slug: 'purchase_request_approver',
    name: 'Purchase Request Approver',
    description: 'Role for approving purchase requests',
    orgType: FGA_ORG_TYPES.customer,
    features: [FGA_FEATURES.request_iq.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  purchase_request_admin: {
    slug: 'purchase_request_admin',
    name: 'Purchase Request Admin',
    description: 'Role for approving purchase requests',
    orgType: FGA_ORG_TYPES.customer,
    features: [FGA_FEATURES.request_iq.slug],
    inheritsIfAnyOf: (): string[] => [
      FGA_ROLES.org_admin.slug,
    ]
  },
  /** @deprecated ! TO BE REMOVED */
  demo_request_submitter: {
    slug: 'demo_request_submitter',
    name: 'Demo Request Submitter',
    description: 'Role for submitting demo requests',
    orgType: FGA_ORG_TYPES.customer,
    features: [FGA_FEATURES.request_iq_demo.slug],
  },
  /** @deprecated ! TO BE REMOVED */
  demo_request_approver: {
    slug: 'demo_request_approver',
    name: 'Demo Request Approver',
    description: 'Role for approving demo requests',
    orgType: FGA_ORG_TYPES.customer,
    features: [FGA_FEATURES.request_iq_demo.slug],
  },
} as const satisfies FGA_ROLES_TYPE
export const fgaRoleSchema = t.Union(Object.values(FGA_ROLES).map(role => t.Literal(role.slug)))
const allRoles = Object.values(FGA_ROLES)
export const FGA_ASSIGNABLE_ROLES: { [roleSlug: string]: FgaRole[] } = {
  [FGA_ROLES.developer.slug]: allRoles,
  [FGA_ROLES.admin.slug]: allRoles.filter(role => role.slug !== FGA_ROLES.developer.slug && role.slug !== FGA_ROLES.admin.slug),
  [FGA_ROLES.org_owner.slug]: allRoles.filter(role => (role.orgType === null || role.orgType === FGA_ORG_TYPES.customer) && role.slug !== FGA_ROLES.org_owner.slug),
  [FGA_ROLES.org_admin.slug]: allRoles.filter(role => (role.orgType === null || role.orgType === FGA_ORG_TYPES.admin) && role.slug !== FGA_ROLES.org_admin.slug && role.slug !== FGA_ROLES.org_owner.slug),
}
export type FgaRole = typeof FGA_ROLES[keyof typeof FGA_ROLES]

/**
 * Admin Orgs can assign all features
 */
export const FGA_ASSIGNABLE_FEATURES: { [roleSlug: string]: FgaFeature[] } = {
  [FGA_ROLES.admin.slug]: Object.values(FGA_FEATURES),
  [FGA_ROLES.developer.slug]: Object.values(FGA_FEATURES),
}
export type FgaFeature = typeof FGA_FEATURES[keyof typeof FGA_FEATURES]
export type FgaFeatureSlug = FgaFeature['slug']
export const FGA_SERVICES = {
  assetiq: 'asset_iq',
  assetiq_pro: 'asset_iq_pro',
  requestiq_demo: 'requestiq_demo',
  requestiq: 'request_iq',
  hierarchy_editor_demo: 'hierarchy_editor_demo',
  system: 'system',
  organization_management_system: 'organization_management_system',
  sales: 'sales',
  unknown: 'unknown',
  public: 'public',
} as const
export type FgaService = typeof FGA_SERVICES[keyof typeof FGA_SERVICES]

export const FGA_SCOPES = {
  public: 'public',
  global: 'global',
  tenant: 'tenant',
  demo_org: 'demo_org',
  user: 'user',
  self: 'self',
  system: 'system',
  org: 'org',
  unknown: 'unknown',
} as const
export type FgaScope = typeof FGA_SCOPES[keyof typeof FGA_SCOPES]

export const FGA_TYPES = {
  user: 'user',
  organization: 'organization',
  featureHolder: 'feature_holder',
  dashboard: 'dashboard',
  dashboardGroup: 'dashboard_group',
  requestGroup: 'request_group',
}
/**
 * These are for constant relations in FGA
 * The rest are dynamic relations, use the makeFgaRelation function to create them.
 */
export const FGA_RELATIONS = {
  hasFeature: 'has_feature',
  parentOrganization: 'parent_organization',
  hasOrganization: 'has_organization',
  hasUser: 'has_user',
  hasGroup: 'has_group',
  computedMembership: 'computed_membership',
  computedViewer: 'computed_viewer',
  computedOrganization: 'computed_organization',
}
/**
 * These are prefixes for FGA relations
 */
export const FGA_RELATION_PREFIXES = {
  role: 'role',
  permission: 'permission',
  endpoint: 'endpoint',
  orgType: 'org_type',
  feature: 'feature',
}
/**
 * These are the keys for policies in warrants in FGA
 */
export const FGA_POLICY_KEYS = {
  workOsOrganizationId: 'workOsOrganizationId',
} as const
